import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Bar, Pie } from 'react-chartjs-2';
import 'chart.js/auto'; // Import Chart.js for charts
import './Dashboard.css'; // Add styles for the dashboard
import Cookies from 'js-cookie';

const API_HOST = process.env.REACT_APP_API_HOST;

function Dashboard() {
  const [stats, setStats] = useState(null); // Set initial state to null
  const [recentLogs, setRecentLogs] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchDashboardData();
  }, []);

  // Fetch dashboard data from backend
  const fetchDashboardData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_HOST}/api/dashboard`, {
        headers: { 'x-access-token': Cookies.get('token') },
      });
      setStats(response.data.stats); // Backend should return key metrics
      setRecentLogs(response.data.logs); // Backend should return recent logs
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
    } finally {
      setLoading(false);
    }
  };

  // Prepare data for bar chart (CronJobs by Status)
  const barData = {
    labels: ['Succeeded', 'Failed', 'Pending'],
    datasets: [
      {
        label: 'CronJobs by Status',
        data: stats ? [stats.succeeded || 0, stats.failed || 0, stats.pending || 0] : [0, 0, 0],
        backgroundColor: ['#4caf50', '#f44336', '#ff9800'],
      },
    ],
  };

  // Prepare data for pie chart (CronJobs by Namespace)
  const pieData = {
    labels: stats?.namespaces ? Object.keys(stats.namespaces) : [],
    datasets: [
      {
        label: 'CronJobs by Namespace',
        data: stats?.namespaces ? Object.values(stats.namespaces) : [],
        backgroundColor: ['#2196f3', '#ff5722', '#9c27b0', '#00bcd4'],
      },
    ],
  };

  return (
    <div className="dashboard-container">
      <h1>Kubernetes CronJob Dashboard</h1>

      {/* Loading State */}
      {loading && <p>Loading dashboard data...</p>}

      {/* Stats Section */}
      <div className="stats-section">
        <div className="stat-card">
          <h3>Total CronJobs</h3>
          <p>{stats?.total || 0}</p>
        </div>
        <div className="stat-card">
          <h3>Active Namespaces</h3>
          <p>{stats?.activeNamespaces || 0}</p>
        </div>
        <div className="stat-card">
          <h3>Failed Jobs</h3>
          <p>{stats?.failed || 0}</p>
        </div>
      </div>

      {/* Charts Section */}
      <div className="charts-section">
        <div className="chart">
          <Bar data={barData} />
        </div>
        <div className="chart">
          <Pie data={pieData} />
        </div>
      </div>

      {/* Recent Logs Section */}
      <div className="logs-section">
        <h2>Recent Activity</h2>
        <table className="table">
          <thead>
            <tr>
              <th>CronJob Name</th>
              <th>Status</th>
              <th>Last Run</th>
              <th>Namespace</th>
            </tr>
          </thead>
          <tbody>
            {recentLogs.length > 0 ? (
              recentLogs.map((log, index) => (
                <tr key={index}>
                  <td>{log.cronjobName}</td>
                  <td style={{ color: log.status === 'Succeeded' ? 'green' : 'red' }}>{log.status}</td>
                  <td>{log.lastRun}</td>
                  <td>{log.namespace}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4">No recent activity available.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Dashboard;